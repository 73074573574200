import React, { useState } from "react";
import { Mail, X } from "lucide-react";
import "./index.css"; // or './App.css' depending on your setup

const HoverTextComponent = () => {
    const [hoveredItem, setHoveredItem] = useState(null);
    const [hoveredIcon, setHoveredIcon] = useState(null);

    const items = [
        { title: "ABOUT", description: "Hey, I'm Nicky." },
        { title: "WORK", description: "Coming soon... (I'm a bit busy)" },
        { title: "CONTACT", description: "nicky@nickygrange.com" },
    ];

    return (
        <div className="flex flex-col h-screen bg-gradient-to-br from-black to-gray-800 text-white p-8">
            <div className="flex justify-end mb-16">
                <div className="flex flex-col items-end">
                    <div className="mb-2 mt-10 sm:mt-10 md:mt-20 pr-10 sm:pr-10 md:pr-40 text-right">
                        <h1 className="text-8xl sm:text-8xl md:text-9xl font-bold animate-pulse">
                            Nicky
                        </h1>

                        <h1 className="text-8xl sm:text-8xl md:text-9xl font-bold animate-pulse">
                            Grange
                        </h1>
                    </div>
                    <div className="flex space-x-4 pr-10 sm:pr-10 md:pr-40">
                        <a
                            href="mailto:nicky@nickygrange.com"
                            onMouseEnter={() => setHoveredIcon("email")}
                            onMouseLeave={() => setHoveredIcon(null)}
                        >
                            <Mail
                                size={50}
                                className={`transition-colors ${
                                    hoveredIcon === "email"
                                        ? "text-purple-600"
                                        : "text-white"
                                }`}
                            />
                        </a>
                        <a
                            href="https://twitter.com/nicky_grange"
                            target="_blank"
                            rel="noopener noreferrer"
                            onMouseEnter={() => setHoveredIcon("twitter")}
                            onMouseLeave={() => setHoveredIcon(null)}
                        >
                            <X
                                size={50}
                                className={`transition-colors ${
                                    hoveredIcon === "twitter"
                                        ? "text-[#00c0f9]"
                                        : "text-white"
                                }`}
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div className="flex flex-1">
                <div className="w-1/2 flex flex-col space-y-4 pl-5 sm:pl-5 md:pl-40 pr-5 sm:pr-5">
                    {items.map((item, index) => (
                        <div
                            key={index}
                            className={`text-2xl md:text-4xl font-bold transition-all duration-300 ${
                                // Changed text size for mobile
                                hoveredItem === index
                                    ? "text-white text-6xl md:text-7xl"
                                    : "text-gray-600 text-5xl md:text-6xl"
                            }`}
                            onMouseEnter={() => setHoveredItem(index)}
                            onMouseLeave={() => setHoveredItem(null)}
                        >
                            {item.title}
                        </div>
                    ))}
                </div>
                <div className="w-1/2 flex flex-col space-y-4 justify-start pt-2">
                    {items.map((item, index) => (
                        <div
                            key={index}
                            className="h-[3.75rem] flex items-center"
                        >
                            {hoveredItem === index && (
                                <div className="font-open-sans font-bold pl-4 sm:text-3xl md:text-3xl transition-opacity duration-300 opacity-100">
                                    {item.description}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default HoverTextComponent;
